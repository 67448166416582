import { useState } from "react";
import { Field, FormSpy } from "react-final-form";
import { TitlePage, PhoneField, CustomButton, Icon, RadioField, DatePickerField } from "~/components";
import { composeValidators, disabledValidate, notEmptyValue, required, testRegExp, validateDependentFields, validateRelatedFields } from "~/utils/validate";
import { Divider } from "antd";
import { CustomInnField, FormField, FormFieldAutocomplete, FormFieldAutocompleteAddress } from "~/pages/questionnaires/shared";
import { ClientId, NamesOfFieldEnum, OrgTypeEnum, TypeSuggestionData } from "~/typings/types";
import { configTitleFieldsOfIndividualEntrepreneur } from "../configTitleFields";
import cn from "classnames";
import { V2Wizard } from "../../../../shared/wizard";
import { getCrmNameByOrgType } from "../../../../shared/utils/getCrmNameByOrgType";
import { FullWidthRowFormContainer } from "~/pages/questionnaires/shared/form-field";

import styles from "./CommonSteps.module.scss";
import '../../styles.scss';
import { PERSONAL_DATA_ADDRESS_SUGGEST, PERSONAL_DATA_FULLNAME_SUGGEST } from "../../../../api/apiUrls";
import { RenderError } from "~/components/error-field";

interface AccountDetailsProps {
  clientId: ClientId;
  orgType: OrgTypeEnum;
  isShowAdditionalFieldsInitially?: boolean;
}

export const AccountDetails = ({
  clientId,
  orgType,
  isShowAdditionalFieldsInitially = false,
}: AccountDetailsProps) => {
  const [showAdditionalFields, setShowAdditionalFields] = useState(isShowAdditionalFieldsInitially);
  const [isDisabledFieldsByInn, setIsDisabledFieldsByInn] = useState(true);

  const toggleAdditionalFields = () => setShowAdditionalFields(!showAdditionalFields);

  return (
    <V2Wizard.Page>
      <TitlePage
        name="Регистрация профиля в системе"
        number="2"
      />
      <Divider style={{ margin: 0 }} />
      <div className={styles.container}>
        <FullWidthRowFormContainer>
          <FormField
            titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.VatMode]}
            name={NamesOfFieldEnum.VatMode}
            required
            tooltip="Вы ИП на режиме для самозанятых — НПД (налог на профессиональный доход)?"
          >
            <div className={styles.containerRadio}>
              <Field
                name={NamesOfFieldEnum.VatMode}
                type="radio"
                value="npd"
                component={props => (
                  <RadioField orderNumber="1" {...props} />
                )}
                validate={disabledValidate(composeValidators(required()))}
              >
                Да
              </Field>
              <Field
                name={NamesOfFieldEnum.VatMode}
                type="radio"
                value="orn"
                component={props => (
                  <RadioField orderNumber="1" {...props} />
                )}
                validate={disabledValidate(composeValidators(required()))}
              >
                Нет
              </Field>
            </div>
          </FormField>
        </FullWidthRowFormContainer>

        <FullWidthRowFormContainer>
          <FormSpy>
            {propsSpy => {

              const errorFullname = propsSpy?.submitErrors?.[NamesOfFieldEnum.CrmNameFirst]
                || propsSpy?.submitErrors?.[NamesOfFieldEnum.CrmNameLast]
                || propsSpy?.submitErrors?.[NamesOfFieldEnum.CrmNamePatron];

              const renderErrorFullname: RenderError = {
                has:
                  Boolean(errorFullname) && ((propsSpy.values?.[NamesOfFieldEnum.CrmNameFirst] && !propsSpy.values?.[NamesOfFieldEnum.CrmNameLast]) ||
                    (!propsSpy.values?.[NamesOfFieldEnum.CrmNameFirst] && propsSpy.values?.[NamesOfFieldEnum.CrmNameLast]))
                    ? 1
                    : 0,
                message: errorFullname
              };

              return (
                <FormFieldAutocomplete
                  titleField={getCrmNameByOrgType(OrgTypeEnum.IndividualEntrepreneur)}
                  name={NamesOfFieldEnum.CrmName}
                  keyValueAutocompleteSelected={("name" as keyof TypeSuggestionData)}
                  required
                  validate={composeValidators(required(), notEmptyValue(),
                    validateRelatedFields(
                      [NamesOfFieldEnum.CrmNameLast, NamesOfFieldEnum.CrmNameFirst],
                      "Укажите фамилию, имя и отчество через пробел и выберите из списка"
                    ))}
                  propsSpy={propsSpy}
                  clientId={clientId}
                  urlPersonalDataAddressSuggest={PERSONAL_DATA_FULLNAME_SUGGEST}
                  handleInputChangeFromProps={() => {
                    propsSpy.form.change(NamesOfFieldEnum.CrmNameFirst, '')
                    propsSpy.form.change(NamesOfFieldEnum.CrmNameLast, '')
                    propsSpy.form.change(NamesOfFieldEnum.CrmNamePatron, '')
                  }}
                  renderError={renderErrorFullname}
                  handleSuggestionClickFromProps={(suggestion) => {
                    if ('name' in suggestion) {
                      suggestion?.first_name && propsSpy.form.change(NamesOfFieldEnum.CrmNameFirst, suggestion.first_name);
                      suggestion?.last_name && propsSpy.form.change(NamesOfFieldEnum.CrmNameLast, suggestion.last_name);
                      suggestion?.patronymic_name && propsSpy.form.change(NamesOfFieldEnum.CrmNamePatron, suggestion.patronymic_name);
                      suggestion?.name && propsSpy.form.change(NamesOfFieldEnum.CrmNameFullname, suggestion.name);
                    }
                  }}
                  orderNumber="2"
                />
              );
            }}
          </FormSpy>
        </FullWidthRowFormContainer>

        <FormField
          titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.CrmPhone]}
          name={NamesOfFieldEnum.CrmPhone}
          required
        >
          <Field
            name={NamesOfFieldEnum.CrmPhone}
            subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
            component={props => <PhoneField orderNumber="3" {...props} />}
            validate={disabledValidate(composeValidators(required()))}
          />
        </FormField>

        <FormField
          titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.CrmEmail]}
          name={NamesOfFieldEnum.CrmEmail}
          required
          validate={composeValidators(
            required(),
            testRegExp(
              /^\s*\S+@\S+\.\S+\s*$/,
              "Пожалуйста, укажите корректный адрес электронной почты"
            )
          )}
          orderNumber="4"
        />

        <FullWidthRowFormContainer>
          <CustomInnField
            innLength={12}
            orderNumber="5"
            orgType={orgType}
            clientId={clientId}
            configNameFields={configTitleFieldsOfIndividualEntrepreneur}
            setIsDisabledFieldsByInn={setIsDisabledFieldsByInn}
          />
        </FullWidthRowFormContainer>

        <FormField
          titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.CrmBirthday]}
          name={NamesOfFieldEnum.CrmBirthday}
          required
        >
          <Field
            name={NamesOfFieldEnum.CrmBirthday}
            subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
            validate={disabledValidate(composeValidators(required()))}
            component={({ input, meta }) => (
              <DatePickerField
                input={input}
                meta={meta}
                orderNumber="6"
              />
            )}
          />
        </FormField>

        <FormField
          titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.Ogrnip]}
          name={NamesOfFieldEnum.Ogrnip}
          maxLength={15}
          mask="3"
          required
          validate={composeValidators(
            required(),
            testRegExp(/^3(\d){14}$/g, 'ОГРНИП должен начинаться на "3" и состоять из 15 цифр')
          )}
          orderNumber="7"
        />

        <FullWidthRowFormContainer>
          <FormSpy>
            {propsSpy => {
              return (
                <FormFieldAutocompleteAddress
                  titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.LegalAddress]}
                  name={NamesOfFieldEnum.LegalAddress}
                  nameFiasId={NamesOfFieldEnum.LegalAddressFiasId}
                  required
                  validate={composeValidators(required(), notEmptyValue())}
                  orderNumber="8"
                  propsSpy={propsSpy}
                  clientId={clientId}
                  urlPersonalDataAddressSuggest={PERSONAL_DATA_ADDRESS_SUGGEST}
                />
              );
            }}
          </FormSpy>
        </FullWidthRowFormContainer>

        <FullWidthRowFormContainer>
          <FormSpy>
            {propsSpy => {
              return (
                <FormFieldAutocompleteAddress
                  titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.PostalAddress]}
                  name={NamesOfFieldEnum.PostalAddress}
                  nameFiasId={NamesOfFieldEnum.PostalAddressFiasId}
                  tooltip="При необходимости направим оригиналы документов на этот адрес"
                  orderNumber="9"
                  propsSpy={propsSpy}
                  clientId={clientId}
                  required
                  validate={composeValidators(required(), notEmptyValue())}
                  triggerAutofill={[NamesOfFieldEnum.PostalAddress, NamesOfFieldEnum.LegalAddress]}
                  triggerAutofillForAddressFiasId={[
                    NamesOfFieldEnum.PostalAddressFiasId,
                    NamesOfFieldEnum.LegalAddressFiasId
                  ]}
                  urlPersonalDataAddressSuggest={PERSONAL_DATA_ADDRESS_SUGGEST}
                />
              );
            }}
          </FormSpy>
        </FullWidthRowFormContainer>

        <Divider className={styles.fullWidthRow} style={{ marginTop: 0 }} />

        <CustomButton
          type="button"
          name={'Дополнительные контакты'}
          id="additional-contacts"
          onClick={toggleAdditionalFields}
          icon={showAdditionalFields ? <Icon name="down-arrow" className={cn(styles.iconDownArrow, styles.iconUpArrow)} /> : <Icon name="down-arrow" className={cn(styles.iconDownArrow)} />}
          className={cn(styles.toggleButton, styles.fullWidthRow)}
        />

        <FullWidthRowFormContainer>
          <p className={styles.additionalDesc} style={{ marginTop: 8 }}>Рекомендуем заполнить данные для оперативной связи по вопросам.</p>
          <p className={styles.additionalDesc}>При оставлении полей пустыми мы автоматически заполним их вашими контактами.</p>
        </FullWidthRowFormContainer>

        <div className={cn(styles.customCollapse, styles.fullWidthRow, { [styles.expanded]: showAdditionalFields })}>
          <div className={styles.collapseContent}>

            <FormField
              titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.LprName]}
              name={NamesOfFieldEnum.LprName}
              tooltip="Укажите контактные данные лица, которое принимает решения по работе проекта"
              orderNumber="10"
              triggerAutofill={[NamesOfFieldEnum.LprName, NamesOfFieldEnum.CrmName]}
            />

            <FormField
              titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.LprPhone]}
              name={NamesOfFieldEnum.LprPhone}
              triggerAutofill={[NamesOfFieldEnum.LprPhone, NamesOfFieldEnum.CrmPhone]}
            >
              <Field
                name={NamesOfFieldEnum.LprPhone}
                subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
                component={props => <PhoneField orderNumber="11" {...props} />}
              />
            </FormField>

            <FormField
              titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.BuhName]}
              name={NamesOfFieldEnum.BuhName}
              tooltip="Укажите контактные данные лица, которое принимает решения по финансовым вопросам проекта"
              orderNumber="12"
              triggerAutofill={[NamesOfFieldEnum.BuhName, NamesOfFieldEnum.CrmName]}
            />

            <FormField
              titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.BuhPhone]}
              name={NamesOfFieldEnum.BuhPhone}
              triggerAutofill={[NamesOfFieldEnum.BuhPhone, NamesOfFieldEnum.CrmPhone]}
            >
              <Field
                name={NamesOfFieldEnum.BuhPhone}
                subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
                component={props => <PhoneField orderNumber="13" {...props} />}
              />
            </FormField>

            <FormField
              titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.TechName]}
              name={NamesOfFieldEnum.TechName}
              tooltip="Укажите контактные данные лица, которое принимает решения по техническим вопросам проекта"
              orderNumber="14"
              triggerAutofill={[NamesOfFieldEnum.TechName, NamesOfFieldEnum.CrmName]}
            />

            <FormField
              titleField={configTitleFieldsOfIndividualEntrepreneur[NamesOfFieldEnum.TechPhone]}
              name={NamesOfFieldEnum.TechPhone}
              triggerAutofill={[NamesOfFieldEnum.TechPhone, NamesOfFieldEnum.CrmPhone]}
            >
              <Field
                name={NamesOfFieldEnum.TechPhone}
                subscription={{ submitFailed: true, error: true, submitError: true, value: true }}
                component={props => <PhoneField orderNumber="15" {...props} />}
              />
            </FormField>
          </div>
        </div>
      </div>
    </V2Wizard.Page>
  );
};
